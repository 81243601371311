import React from 'react';
import AboutCard from './AboutCard';
import { FaBuilding, FaTools, FaBookOpen } from 'react-icons/fa';  // Import appropriate icons

const About = () => {
  return (
    <div name='about' className='w-full my-56 lg:my-32'>
        <div className='max-w-6xl px-4 mx-auto'>
            <div className='text-center'>
                <h1 className='my-3 text-5xl font-bold text-gray-800'>Trusted by Companies and People across the World.</h1>
                <p className='mb-3 text-2xl text-gray-500'>Empowering solutions with cutting-edge technology and unmatched expertise.</p>
            </div>
            <div className='grid gap-4 py-3 text-center sm:grid-cols-3'>
                <AboutCard
                    icon={<FaBuilding />}
                    head="About Us"
                    tag="We cherish the wisdom in offering help and support, utilizing our vast experience and knowledge."
                />
                <AboutCard
                    icon={<FaTools />}
                    head="What We Do"
                    tag="Solvenetics provides solutions for the digital transformation of security and operations through software-hardware integration."
                />
                <AboutCard
                    icon={<FaBookOpen />}
                    head="Our Policy"
                    tag= "We are committed to ensuring the confidentiality, integrity, and availability of information in all formats."
                   />
            </div>
        </div>
    </div>
  )
}

export default About;