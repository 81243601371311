import React from "react";
import {
  CloudUploadIcon,
  DatabaseIcon,
  MapIcon,
  CodeIcon,
} from "@heroicons/react/solid";
import Cyber from "../assets/laptop.png";

const Intro = () => {
  return (
    <div
      name="home"
      className="flex flex-col justify-between w-full h-screen bg-slate-100"
    >
      <div className="grid max-w-6xl m-auto lg:grid-cols-2">
        <div className="flex flex-col justify-center w-full px-4 py-8 text-center lg:text-left">
          <p className="px-1 text-lg font-semibold md:text-xl mt-32">
            We Deliberate and Solve{" "}
          </p>
          <h1 className="py-2 text-5xl font-bold text-gray-800 md:text-6xl">
            Specialized consultancy in advanced digital security and research.
          </h1>
          {/* <p className='px-1 text-lg font-semibold'>This is our Tech brand.</p> */}
          <a
            href="https://wa.me/923228880125"
            target="_blank"
            rel="noopener noreferrer"
          >
            <button className="w-full px-4 py-2 my-6">Get Started!</button>
          </a>
        </div>

        <div className="grid place-items-center">
          <img src={Cyber} className=" w-96 lg:w-full " />
        </div>
        <div className="absolute hover:scale-105 duration-300 flex flex-col py-4 px-4 lg:min-w-[760px] bottom-[-10%] md:bottom-[-10%] lg:bottom-[-8%] mx-1 lg:left-1/2 transform lg:-translate-x-1/2 bg-sky-50 border rounded-xl border-sky-400 shadow-sky-100 text-center shadow-xl lg:block hidden">
          <p className="mb-2 text-xl text-gray-800 md:text-2xl">
            Our Services
          </p>
          <div className="flex flex-col justify-between sm:flex-row ">
            <p className="flex px-4 py-2 text-sm md:text-lg text-slate-500">
              <CloudUploadIcon className="w-5 mr-2 text-sky-500 md:w-7" />
              Cloud Services
            </p>
            <p className="flex px-4 py-2 text-sm md:text-lg text-slate-500">
              <DatabaseIcon className="w-5 mr-2 text-sky-500 md:w-7" />
              Data Management
            </p>
            <p className="flex px-4 py-2 text-sm md:text-lg text-slate-500">
              <MapIcon className="w-5 mr-2 text-sky-500 md:w-7" />
              GIS & Mapping
            </p>
            <p className="flex px-4 py-2 text-sm md:text-lg text-slate-500">
              <CodeIcon className="w-5 mr-2 text-sky-500 md:w-7" />
              Low Code & No Code Solutions
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Intro;