import React from 'react';
import { IconContext } from 'react-icons';

const AboutCard = ({ icon, head, tag }) => {
    return (
        <div className='py-10 my-2 duration-300 border border-sky-400 shadow-xl hover:scale-105 rounded-xl bg-sky-50 shadow-sky-100 text-center flex flex-col items-center justify-center'>
            <IconContext.Provider value={{ className: 'text-sky-600 text-5xl mb-4' }}>
                {icon}
            </IconContext.Provider>
            <h1 className='text-3xl font-bold text-sky-600'>{head}</h1>
            <p className='text-lg text-gray-600 mt-2'>{tag}</p>
        </div>
    )
}

export default AboutCard