import React from 'react';

import { PhoneIcon, ArrowSmRightIcon } from '@heroicons/react/outline';
import { ChipIcon, SupportIcon } from '@heroicons/react/solid';

import SupportImg from '../assets/support.jpeg';

const Support = () => {
    const whatsappLink = "https://wa.me/923228880125";  // WhatsApp contact link

    return (
        <div name='support' className='w-full mt-24 relative'>
            <div className='relative w-full h-[700px] bg-gray-900/90'>
                <img className='object-cover w-full h-full mix-blend-overlay' src={SupportImg} alt="Support" />
            </div>

            <div className='relative max-w-6xl mx-auto text-white mt-[-700px]'>
                <div className='px-4 py-12 text-center'>
                    <h1 className='pt-8 text-3xl text-gray-300'>Support</h1>
                    <h2 className='py-4 text-5xl font-bold'>Finding the Right Team</h2>
                    <p className='py-4 text-2xl text-gray-400'>
                        Our dedicated team is here to assist you with any questions or concerns. Whether you need help with sales, technical issues, or media inquiries, we're ready to provide the support you need.
                    </p>
                </div>

                <div className='grid px-4 pt-12 text-black sm:pt-20 lg:grid-cols-3 gap-x-8 gap-y-16'>
                    <div className='duration-300 border border-indigo-400 shadow-2xl bg-indigo-50 shadow-indigo-200 rounded-xl hover:scale-105'>
                        <div className='p-8'>
                            <PhoneIcon className='w-16 p-3 text-white bg-sky-600 rounded-xl mt-[-4rem]' />
                            <h3 className='py-4 text-2xl font-bold text-gray-700'>Sales</h3>
                            <p className='text-gray-600'>
                                Need information about our products and services? Our sales team is here to help.
                            </p>
                        </div>
                        <a href={whatsappLink} target="_blank" rel="noopener noreferrer" className='bg-indigo-100 cursor-pointer rounded-b-xl'>
                            <p className='flex px-6 py-2 text-sky-700'>
                                Contact Us <ArrowSmRightIcon className='w-6' />
                            </p>
                        </a>
                    </div>
                    
                    <div className='duration-300 border border-indigo-400 shadow-2xl bg-indigo-50 shadow-indigo-200 rounded-xl hover:scale-105'>
                        <div className='p-8'>
                            <SupportIcon className='w-16 p-3 text-white bg-sky-600 rounded-xl mt-[-4rem]' />
                            <h3 className='py-4 text-2xl font-bold text-gray-700'>Technical Support</h3>
                            <p className='text-gray-600'>
                                Facing technical issues? Our support team is available to assist with any problems you encounter.
                            </p>
                        </div>
                        <a href={whatsappLink} target="_blank" rel="noopener noreferrer" className='bg-indigo-100 cursor-pointer rounded-b-xl'>
                            <p className='flex px-6 py-2 text-sky-700'>
                                Contact Us <ArrowSmRightIcon className='w-6' />
                            </p>
                        </a>
                    </div>

                    <div className='duration-300 border border-indigo-400 shadow-2xl bg-indigo-50 shadow-indigo-200 rounded-xl hover:scale-105'>
                        <div className='p-8'>
                            <ChipIcon className='w-16 p-3 text-white bg-sky-600 rounded-xl mt-[-4rem]' />
                            <h3 className='py-4 text-2xl font-bold text-gray-700'>Media Inquiries</h3>
                            <p className='text-gray-600'>
                                For media and press inquiries, get in touch with our team for information and assistance.
                            </p>
                        </div>
                        <a href={whatsappLink} target="_blank" rel="noopener noreferrer" className='bg-indigo-100 cursor-pointer rounded-b-xl'>
                            <p className='flex px-6 py-2 text-sky-700'>
                                Contact Us <ArrowSmRightIcon className='w-6' />
                            </p>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Support;