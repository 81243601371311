import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from "./components/Navbar";
import Intro from "./components/Intro";
import About from "./components/About";
import Support from "./components/Support";
import AllInOne from "./components/AllInOne";
// import Pricing from "./components/Pricing";
import Footer from "./components/Footer";
import PrivacyPolicy from "./components/Privacy";

function MainContent() {
  return (
    <>
      <Intro />
      <About />
      <Support />
      <AllInOne />
      {/* <Pricing /> */}
    </>
  );
}

function App() {
  return (
    <div className="flex flex-col min-h-screen">
      <Navbar />
      <main className="flex-grow">
        <Routes>
          <Route path="/" element={<MainContent />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        </Routes>
      </main>
      <Footer />
    </div>
  );
}

export default App;
