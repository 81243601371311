import React from 'react';
import { CloudUploadIcon, DatabaseIcon, PaperAirplaneIcon, ServerIcon, CameraIcon, MapIcon, PhoneIcon, DocumentTextIcon, WifiIcon, SatelliteIcon, CodeIcon, PhoneIncomingIcon } from '@heroicons/react/solid';

const features = [
    {
        icon: <CloudUploadIcon className='w-8 text-sky-500' />,
        title: 'Cloud Solutions',
        description: 'Secure and scalable cloud services to support your business needs.'
    },
    {
        icon: <DatabaseIcon className='w-8 text-green-500' />,
        title: 'Data Management',
        description: 'Robust data management solutions to keep your information organized and accessible.'
    },
    {
        icon: <PaperAirplaneIcon className='w-8 text-sky-500' />,
        title: 'Consulting Services',
        description: 'Expert consulting to help you navigate and optimize your IT infrastructure.'
    },
    {
        icon: <ServerIcon className='w-8 text-red-500' />,
        title: 'Server Management',
        description: 'Efficient server management to ensure high performance and reliability.'
    },
    {
        icon: <CameraIcon className='w-8 text-yellow-500' />,
        title: 'CCTV & Facial Recognition',
        description: 'Advanced analytics and facial recognition for enhanced security solutions.'
    },
    {
        icon: <MapIcon className='w-8 text-orange-500' />,
        title: 'GIS & Mapping Solutions',
        description: 'Comprehensive GIS surveys and mapping solutions tailored for precise navigation and planning.'
    },
    {
        icon: <PhoneIcon className='w-8 text-blue-500' />,
        title: 'Emergency Calling & Contact Center Solutions',
        description: 'Reliable emergency call systems and contact center solutions for improved communication.'
    },
    {
        icon: <DocumentTextIcon className='w-8 text-purple-500' />,
        title: 'Document Management Solutions',
        description: 'Efficient management of documents, including health-related records, ensuring safety and accessibility.'
    },
    {
        icon: <WifiIcon className='w-8 text-teal-500' />,
        title: 'Wireless & Satellite Internet Solutions',
        description: 'Robust wireless communication and high-speed satellite internet solutions to keep you connected.'
    },
    {
        icon: <CodeIcon className='w-8 text-lime-500' />,
        title: 'Low Code & No Code Solutions',
        description: 'Simplify software development with cutting-edge low code and no code platforms.'
    },
    // {
    //     icon: <SoftwareIcon className='w-8 text-indigo-500' />,
    //     title: 'Custom Software Solutions',
    //     description: 'Tailored software solutions to meet the unique needs of your business operations.'
    // },
    {
        icon: <PhoneIncomingIcon className='w-8 text-pink-500' />,
        title: 'SIP/IP Based Communication Solutions',
        description: 'Advanced SIP/IP communication solutions to enhance your business connectivity.'
    },
];

const AllInOne = () => {
    return (
        <div name='platforms' className='w-full my-32'>
            <div className='max-w-6xl px-4 mx-auto'>
                <h2 className='text-5xl font-bold text-center text-gray-800'>All-In-One Platform</h2>
                <p className='py-8 text-2xl text-center text-gray-600'>
                    Discover the comprehensive solutions we offer to enhance your business operations.
                </p>
                <div className='grid gap-4 pt-4 sm:grid-cols-2 lg:grid-cols-4'>
                    {features.map((feature, index) => (
                        <div key={index} className='flex items-start p-4 bg-white shadow-md rounded-lg'>
                            <div className='flex-shrink-0'>
                                {feature.icon}
                            </div>
                            <div className='ml-4'>
                                <h3 className='text-lg font-bold text-gray-800'>{feature.title}</h3>
                                <p className='mt-2 text-gray-700'>{feature.description}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default AllInOne;